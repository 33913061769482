import useSWR from "swr";
import { get, map, slice, round } from "lodash";
import Link from "next/link";
import LangLink from "components/_others/link/Link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";

import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

import styles from "./Footer.module.scss";

import Image from "next/image";
import { useTranslation } from "next-i18next";
import upperFirst from "lodash/upperFirst";

const arukereso_footer = `
  <div style="background: transparent; text-align: left; padding: 0; width: 120px">
    <a title="Árukereső.hu" href="https://www.arukereso.hu/" style="display: block;border:0; padding:0;margin:0">
      <img style="padding: 0; margin:0; border:0" alt="Árukereső.hu" src="https://static.arukereso.hu/hu/logo-120.png"/>
    </a>
    <a title="Árukereső.hu" style="line-height:16px;font-size: 11px; font-family: Arial, Verdana; color: white" href="https://www.arukereso.hu/">
      Árukereső.hu
    </a>
  </div>`;

const Footer = () => {
  const { t } = useTranslation();
  const { data } = useSWR(
    "/api/pages/footer",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const webinstance = get(data, "webinstance", {});
  const statics = get(data, "statics", []);
  const categories = get(data, "categories", []);

  const year = new Date().getFullYear();

  return (
    <>
      {/* <div className={styles.newsletter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles["col-2"]}`}></div>
            <div className={`${styles.col} ${styles["col-6"]}`}>
              <h5>Newsletter</h5>
              <ul>
                <li>
                  zúčastnite sa uzavretých akcií len pre zákaznikov newslettru
                </li>
                <li>o novinkách sa dozviete ako prvý</li>
              </ul>
            </div>
            <div className={`${styles.col} ${styles["col-2"]}`}>
              <a
                className={styles.subscribe}
                href="https://eepurl.com/du5xdz"
                target="_blank"
                rel="nofollow"
                rel="noopener"
              >
                Odoberať
              </a>
            </div>
            <div className={`${styles.col} ${styles["col-2"]}`}></div>

            <!-- SALESMANAGO NEWSLETTER -->
            <iframe
              id="salesmanagoIframe"
              src="https://app3.salesmanago.pl/mscf/rd124utrg7pp494s/default/My_new_iframe_2020-12-30.htm"
            ></iframe>
          </div>
        </div>
      </div> */}
      <footer id={styles.footer}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles["col-3"]}`}>
              <address>
                <div>
                  <a href="/">
                    <Image
                      src={"/foon_logo_white_small.gif"}
                      width={200}
                      height={47}
                      alt={"Foon logo"}
                    />
                  </a>
                </div>
                <a href={`tel:${get(webinstance, "globalSettings.phone")}`}>
                  {get(webinstance, "globalSettings.phone")}
                </a>
                <br />
                <a href={`mailto:${webinstance?.adminEmail}`}>
                  {webinstance?.adminEmail}
                </a>
                <br />
                <br />
                {get(webinstance, "globalSettings.name_of_company")}
                <br />
                {get(webinstance, "globalSettings.street_and_number")}
                <br />
                {get(webinstance, "globalSettings.ZIP")}{" "}
                {get(webinstance, "globalSettings.city")} <br />
                {get(webinstance, "globalSettings.state")} <br />
                <br />
                <a
                  href={`https://maps.google.com/?q=${get(
                    webinstance,
                    "globalSettings.name_of_company"
                  )},${get(
                    webinstance,
                    "globalSettings.street_and_number"
                  )},${get(webinstance, "globalSettings.ZIP")},${get(
                    webinstance,
                    "globalSettings.city"
                  )}`}
                  className={styles["map-anchor"]}
                >
                  {t("common:show on map")}
                </a>
              </address>
              <div className={styles.socials}>
                <div className={styles.icons}>
                  {get(webinstance, "globalSettings.facebook_link") ? (
                    <a
                      href={get(webinstance, "globalSettings.facebook_link")}
                      target="_blank"
                      rel="nofollow"
                      rel="noopener"
                      className={styles.icon}
                    >
                      <FacebookOutlined />
                    </a>
                  ) : (
                    ""
                  )}

                  {get(webinstance, "globalSettings.instagram_link") ? (
                    <a
                      href={get(webinstance, "globalSettings.instagram_link")}
                      target="_blank"
                      rel="nofollow"
                      rel="noopener"
                      className={styles.icon}
                    >
                      <InstagramOutlined />
                    </a>
                  ) : (
                    ""
                  )}

                  {get(webinstance, "globalSettings.youtube_link") ? (
                    <a
                      href={get(webinstance, "globalSettings.youtube_link")}
                      target="_blank"
                      rel="nofollow"
                      rel="noopener"
                      className={styles.icon}
                    >
                      <YoutubeOutlined />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: arukereso_footer,
                }}
              />
            </div>
            <div className={`${styles.col} ${styles["col-3"]}`}>
              <h3>{upperFirst(t("common:categories"))}</h3>
              {categories ? (
                <ul>
                  {map(categories, (category, index) => {
                    return (
                      <li key={`footer-category-${category?.id}-${index}`}>
                        {category?.externalUrl ? (
                          <a href={category?.externalUrl}>{category?.name}</a>
                        ) : (
                          <Link
                            {...getCategoryLinkAttributes(
                              get(category, "urlName")
                            )}
                            scroll={true}
                          >
                            <a>{get(category, "name")}</a>
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </div>
            <div className={`${styles.col} ${styles["col-6"]}`}>
              {statics ? (
                <>
                  <h3>{upperFirst(t("common:information"))}</h3>
                  <div className={styles.row}>
                    <div className={`${styles.col} ${styles["no-padding"]}`}>
                      <ul>
                        {map(
                          slice(statics, 0, round(statics.length / 2)),
                          (item, index) => {
                            return (
                              <li
                                key={`footer-static-page-row1-${item?.id}-${index}`}
                              >
                                <LangLink
                                  href={{
                                    pathname: "/stranka/[id]",
                                    query: { id: get(item, "urlTitle") },
                                  }}
                                >
                                  <a>{get(item, "title")}</a>
                                </LangLink>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div className={`${styles.col} ${styles["no-padding"]}`}>
                      <ul>
                        {map(
                          slice(statics, round(statics.length / 2)),
                          (item, index) => {
                            return (
                              <li
                                key={`footer-static-page-${item?.id}-${index}`}
                              >
                                <LangLink
                                  href={{
                                    pathname: "/stranka/[id]",
                                    query: { id: get(item, "urlTitle") },
                                  }}
                                >
                                  <a>{get(item, "title")}</a>
                                </LangLink>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col + " " + styles.copyright}>
            {year} © mobilonline | {t("common:powered by eshop system")}{" "}
            <a href="https://www.grandus.sk" rel="nofollow sponsored">
              Grandus
            </a>{" "}
            {t("common:from the company")}{" "}
            <a href="https://www.forbestclients.com" rel="nofollow sponsored">
              For Best Clients, s.r.o.
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
