import get from "lodash/get";

const routes = {
  "/blog": {
    sk: "/blog",
  },
  "/blog/[id]": {
    sk: "/blog/[id]",
  },
  "/blog/kategoria/[id]/[[...category]]": {
    sk: "/blog/kategoria/[id]/[[...category]]",
  },
  "/kategoria/[category]/[[...parameters]]": {
    sk: "/kategoria/[category]/[[...parameters]]",
  },
  "/kategoria/[category]/~filter/[...filter]": {
    sk: "/kategoria/[category]/~filter/[...filter]",
  },
  "/kategoria/[category]/filter/[...filter]": {
    sk: "/kategoria/[category]/filter/[...filter]",
  },
  "/kosik": {
    sk: "/kosik",
    hu: "/kosar",
  },
  "/kosik/doprava-a-platba": {
    sk: "/kosik/doprava-a-platba",
    hu: "/kosar/szallitas-es-fizetes",
  },
  "/kosik/kontakt": {
    sk: "/kosik/kontakt",
    hu: "/kosar/kapcsolat",
  },
  "/objednavka/dakujeme": {
    sk: "/objednavka/dakujeme",
    hu: "/rendelos/koszonjuk",
  },
  "/odhlasenie": {
    sk: "/odhlasenie",
    hu: "/kijelentkezes",
  },
  "/porovnanie": {
    sk: "/porovnanie",
    hu: "/osszehasonlitas",
  },
  "/predajna/[id]": {
    sk: "/predajna/[id]",
    hu: "/uzlet/[id]",
  },
  "/predajne": {
    sk: "/predajne",
    hu: "/uzletek",
  },
  "/prihlasenie": {
    sk: "/prihlasenie",
    hu: "/bejelentkezes",
  },
  "/produkt/[id]": {
    sk: "/produkt/[id]",
    hu: "/termek/[id]",
  },
  "/registracia": {
    sk: "/registracia",
    hu: "/regisztracio",
  },
  "/stranka/[id]": {
    sk: "/stranka/[id]",
    hu: "/oldal/[id]",
  },
  "/ucet/dodacie-listy": {
    sk: "/ucet/dodacie-listy",
    hu: "/fiok/szallito-levelek",
  },
  "/ucet/faktury": { sk: "/ucet/faktury", hu: "/fiok/szamlak" },
  "/ucet/kredity": { sk: "/ucet/kredity", hu: "/fiok/kreditek" },
  "/ucet/kupony": { sk: "/ucet/kupony", hu: "/fiok/kuponok" },
  "/ucet/objednavky": {
    sk: "/ucet/objednavky",
    hu: "/fiok/rendelesek",
  },
  "/ucet/objednavky/[id]": {
    sk: "/ucet/objednavky/[id]",
    hu: "/fiok/rendelesek/[id]",
  },
  "/ucet/profil": {
    sk: "/ucet/profil",
    hu: "/fiok/profil",
  },
  "/ucet/unauthorized": {
    sk: "/ucet/unauthorized",
    hu: "/fiok/nem-engedelyezett",
  },
  "/vyhladavanie/[term]/[[...parameters]]": {
    sk: "/vyhladavanie/[term]/[[...parameters]]",
    hu: "/kereses/[term]/[[...parameters]]",
  },
  "/wishlist": { sk: "/wishlist", hu: "/kedvencek" },
  "/zabudnute-heslo": { sk: "/zabudnute-heslo", hu: "/elfelejtett-jelszo" },
  "/zabudnute-heslo/[hash]": {
    sk: "/zabudnute-heslo/[hash]",
    hu: "/elfelejtett-jelszo/[hash]",
  },
};

export const getPathname = (pathname, locale = false) => {
  return locale ? get(routes, [pathname, locale], pathname) : pathname;
};

export const getUrlObject = (pathname, query = {}, locale = "") => {
  return {
    pathname: getPathname(pathname, locale),
    query,
  };
};
